import { Menu } from '@/models/MetaInformation';

export default [
    {
        name: 'Billing',
        path: '/billing',
        component: require('@/pages/routeWrapper.vue').default,
        redirect: { name: 'prepaid-overview' },
        children: [
            {
                path: 'prepaids',
                name: 'prepaid-overview',
                component: require('@/modules/prepaid/views/overview.vue').default,
                meta: {
                    menu: new Menu({ showInMenu: true, icon: 'dollar-sign', label: 'Prepaids', roles: ['SuperAdmin'] }),
                },
            },
            {
                path: 'partners',
                name: 'invoice-partner-overview',
                component: require('@/modules/invoices/views/partner-overview.vue').default,
                meta: {
                    menu: new Menu({ showInMenu: true, icon: 'finance', label: 'Partner Invoices', roles: ['SuperAdmin', 'Partner'] }),
                },
            },
            {
                path: 'partners/details',
                name: 'invoice-partner-details',
                component: require('@/modules/invoices/views/partner-details.vue').default,
                meta: {
                    menu: new Menu({ showInMenu: false }),
                },
            },
            {
                path: 'practitioners',
                name: 'invoice-practitioner-overview',
                component: require('@/modules/invoices/views/practitioner-overview.vue').default,
                meta: {
                    menu: new Menu({
                        showInMenu: true,
                        icon: 'finance',
                        label: 'Practitioner Invoices',
                        roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                        permissions: ['DirectInvoicing'],
                    }),
                },
            },

            {
                path: 'practitioners/details',
                name: 'invoice-practitioner-details',
                component: require('@/modules/invoices/views/practitioner-details.vue').default,
                meta: {
                    menu: new Menu({ showInMenu: false }),
                },
            },
        ],
        meta: {
            menu: new Menu({
                showInMenu: true,
                icon: 'finance',
                label: 'Invoices',
                variant: 'success-clean',
                roles: ['SuperAdmin', 'Practitioner', 'Partner'],
                permissions: ['DirectInvoicing'],
            }),
        },
    },
];
