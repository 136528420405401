import { GroupByType } from './enums/GroupByType';
import { InvoiceType } from './enums/InvoiceType';

export default class InvoiceFilter {
    public groupByType: GroupByType = GroupByType.All;
    public receiver: InvoiceType;
    public partnerId: number;
    public practitionerId: number;
    public knowledgeModelId : number;
    public month: number;
    public year: number;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
