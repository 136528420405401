import { saveExcel } from '@progress/kendo-vue-excel-export';
import { Component, Watch } from 'vue-property-decorator';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import BasePage from '@/models/BasePage';
import InvoiceFilter from '../models/InvoiceFilter';
import { GroupByType } from '../models/enums/GroupByType';
import { InvoiceService } from '../services/invoiceService';
import Vue from 'vue';
import to from 'await-to-js';
import Invoice from '../models/Invoice';
import { InvoiceStatus } from '../models/enums/InvoiceStatus';
import { InvoiceType } from '../models/enums/InvoiceType';

@Component({})
export default class InvoicesView extends BasePage {
    public invoice = new Invoice();
    public invoiceService: InvoiceService = new InvoiceService();

    public invoiceFilter = new InvoiceFilter({
        groupByType: GroupByType.All,
    });

    public groupOptions: any[] = [];
    public groupSelected: GroupByType = GroupByType.All;

    public exportColumns = [
        { field: 'project', title: 'Project' },
        { field: 'practitioner', title: 'Practitioner' },
        { field: 'participant', title: 'Participant' },
        { field: 'organization', title: 'Organization' },
        { field: 'completionDate', title: 'Completion date' },
        { field: 'pricePlan', title: 'Price plan' },
        { field: 'endUserPricing', title: 'End user pricing' },
        { field: 'instrument', title: 'Instrument' },
        { field: 'partnerFee', title: 'Partner fee' },
        { field: 'totalInvoicing', title: 'Total invoicing' },
        { field: 'remainingPrepaid', title: 'New balance' },
        { field: 'partnerInvoiceAmount', title: 'Partner invoice amount' },
    ];

    public columns: GridColumnProps[] = [
        { field: 'project', title: 'Project' },
        { field: 'practitioner', title: 'Practitioner' },
        { field: 'participant', title: 'Participant' },
        { field: 'organization', title: 'Organization' },
        { field: 'completionDate', title: 'Completion date', cell: Vue.component('grid-date-display'), width: 150 },
        { field: 'pricePlan', title: 'Price plan', width: 175 },
        { field: 'endUserPricing', title: 'End user pricing', format: '{0:c}', width: 140 },
        { field: 'instrument', title: 'Instrument', width: 100 },
        { field: 'partnerFee', title: 'Partner fee', format: '{0:c}', width: 150 },
        { field: 'totalInvoicing', title: 'Total invoicing', format: '{0:c}', width: 150 },
        { field: 'remainingPrepaid', title: 'New balance', format: '{0:c}', width: 150 },
        { field: 'partnerInvoiceAmount', title: 'Partner invoice amount', format: '{0:c}', width: 150 },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: [
            'project',
            'practitioner',
            'participant',
            'organization',
            'startDate',
            'closeDate',
            'pricePlan',
            'endUserPricing',
            'instrument',
            'partnerFee',
            'payment',
            'totalInvoicing',
            'remainingPrepaid',
            'partnerInvoiceAmount',
        ],
    });

    @Watch('groupSelected')
    public async onPropertyChanged(value: GroupByType) {
        this.invoiceFilter.groupByType = value;
        await this.getInvoiceLines();
    }

    public async mounted() {
        if (this.$route.params.invoiceType === 'practitioner') {
            this.columns = this.columns.filter((x) => x.field !== 'partnerInvoiceAmount').filter((x) => x.field !== 'partnerFee');
        }

        this.invoiceFilter.knowledgeModelId = parseInt(this.$route.query.knowledgeModelId.toString())
        this.invoiceFilter.partnerId = parseInt(this.$route.query.partnerId.toString())
        this.invoiceFilter.month = parseInt(this.$route.query.month.toString())
        this.invoiceFilter.year = parseInt(this.$route.query.year.toString())

        await this.getInvoiceLines();

        this.groupOptions = this.createGroupingOptions();

        this.isLoaded = true;
    }

    public async exportInvoices() {
        saveExcel({
            data: this.invoice.invoiceLines,
            fileName: `Invoice-${this.$route.params.invoiceId}`,
            columns: this.exportColumns,
        });
    }

    public async changeInvoiceStatus(status: InvoiceStatus) {
        this.showPending('Updating invoice...');
        this.invoice.invoiceStatus = status;
        const [err] = await to(this.invoiceService.updateInvoice(this.invoice));
        if (err) {
            return this.clearAndShowError('Updating invoice failed!', err);
        }

        this.clearAndShowSuccess('Updating invoice success!');
        this.invoice.invoiceStatus = status;
    }

    private createGroupingOptions(): any[] {
        const array = [
            { text: 'Show seperate surveys', value: GroupByType.All },
            { text: 'Group based on Project', value: GroupByType.Project },
            { text: 'Group based on Organization', value: GroupByType.Organization },
            { text: 'Group based on Practitioner', value: GroupByType.Practitioner },
        ];

        return array;
    }

    private async getInvoiceLines() {
        const [err, response] = await to(this.invoiceService.getInvoiceLines(this.invoiceFilter));
        if (err || !response) {
            return this.clearAndShowError(
                `The invoices details couldn't be retrieved, please refresh the page to see if that solves the problem. If the problem still exists, contact us with the support button.`,
                err,
            );
        }

        this.invoice = response.data;
    }
}
